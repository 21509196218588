<template>
  <div class="modal fade " :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">
            {{modalUsagesFor === 0 || modalUsagesFor === 1 ? "Update User Information" : "Update System Role Information" }}
          </h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row ">
              <div v-if="modalUsagesFor !== 2" class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>First name *</label>
                    <input v-model="putUserData.first_name" type="text" class="form-control " placeholder="Enter first name" />
                    <div class="text-danger" v-if="errors.firstName">{{ errors.firstName }}</div>
                  </div>
                </div>
              </div>
              <div v-if="modalUsagesFor !== 2" class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Last name *</label>
                    <input v-model="putUserData.last_name" type="text" class="form-control " placeholder="Enter last name" />
                    <div class="text-danger" v-if="errors.lastName">{{ errors.lastName }}</div>
                  </div>
                </div>
              </div>
              <div v-if="modalUsagesFor !== 2" class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Phone number</label>
                    <input v-model="putUserData.phone_number" type="tel" class="form-control " placeholder="ie. 0412345678" />
                    <div class="text-danger" v-if="errors.phoneNumber">{{ errors.phoneNumber }}</div>
                  </div>
                </div>
              </div>
              <div v-if="modalUsagesFor !== 2" class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Email address *</label>
                    <input v-model="putUserData.email" type="email" class="form-control " placeholder="ie. example@gmail.com"/>
                    <div class="text-danger" v-if="errors.email">{{ errors.email }}</div>
                  </div>
                </div>
              </div>
              <div v-if="modalUsagesFor !== 2 && dateOfBirthInputActive" :class="dateOfBirthDivClass">
                <div class="form-group">
                  <label>Date of Birth</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1 ">
                      <div class="w-100 ">
                        <DatePicker v-model="putUserData.date_of_birth" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue" v-on="inputEvents" />
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.dateOfBirth">{{ errors.dateOfBirth }}</div>
                </div>
              </div>
              <div v-if="modalUsagesFor !== 1" :class="roleSelectionDivClass">
                <div class="form-group">
                  <label>System Using Role</label>
                  <VueMultiselect v-model="selectedRole" :options="roles" :close-on-select="true" placeholder="Select state" label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                  <div class="text-danger" v-if="errors.roleId">{{ errors.roleId }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="submit" class="btn btn-primary" @click="updateSingleUser">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

// core packages
import { mapGetters,mapActions } from 'vuex';

// packages
import { DatePicker } from 'v-calendar';
import VueMultiselect from 'vue-multiselect';

export default {
  name: "userUpdateModal",
  mixins: [ShowToastMessage],
  props: {
    modalName: {
      type: String,
      default: "userAndUserRoleUpdateModal"
    },
    modalUsagesFor: {
      type: Number,
      default: 0, // 0 for update both user and user-role, 1 for update user only, 2 for update user-role only.
      required: true,
    },
    userId: {
      type: [Number, String],
      required: true,
    },
    userData: {
      type: Object,
      required: false,
      default: () => ({firstName: '', lastName: '', email: '', phoneNumber: '', dateOfBirth: ''}),
    },
    userRoleId: {
      type: [Number, String],
      required: false,
    },
    getRolesParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    selectedRoleData: {
      type: Object,
      required: false,
      default: () => ({value: '', name: 'None'}),
    },
    dateOfBirthInputActive: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  components: {
    DatePicker,
    VueMultiselect,
  },
  data() {
    return {
      putUserData: {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      },
      selectedRole: {
        value: '',
        name: 'None',
      },
      errors: {
        roleId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
      },
    }
  },

  computed: {
    ... mapGetters({
      user: 'appUsers/user',
    }),
    roleSelectionDivClass() {
      if (this.modalUsagesFor === 2) {
        return 'col-12';
      }
      return 'col-12 col-md-6';
    },

    dateOfBirthDivClass() {
      if (this.modalUsagesFor === 1) {
        return 'col-12';
      }
      return 'col-12 col-md-6';
    },

    roles() {
      let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {
          value: roleId,
          name: roleName
        };
      });

      return [{
        value: '',
        name: 'None'
      }, ...activeRoles];
    },
  },

  watch: {
    userData(userData) {
      this.putUserData.first_name    = userData.firstName;
      this.putUserData.last_name     = userData.lastName;
      this.putUserData.email         = userData.email;
      this.putUserData.phone_number  = userData.phoneNumber;
      this.putUserData.date_of_birth = userData.dateOfBirth;
    },
    userRoleId(userRoleId) {
      this.putUserData.role_id = userRoleId;
    },
    selectedRoleData(selectedRoleData) {
      this.selectedRole.value = selectedRoleData.value;
      this.selectedRole.name  = selectedRoleData.name;
    },
    selectedRole(selectedRole) {
      this.putUserData.role_id = selectedRole.value;
    },
  },
  methods: {
    ...mapActions({
      putUser: 'appUsers/putUser',
      getRoles: 'appRoles/getRoles',
    }),
    async getRoleList() {
      await this.getRoles(this.getRolesParams);
    },

    async updateSingleUser() {
      let data = {};
      if (this.modalUsagesFor === 0) {
        data = {
          role_id: this.putUserData.role_id,
          first_name: this.putUserData.first_name,
          last_name: this.putUserData.last_name,
          email: this.putUserData.email,
          phone_number: this.putUserData.phone_number,
          date_of_birth: this.putUserData.date_of_birth,
        };
      }
      if (this.modalUsagesFor === 1) {
        data = {
          first_name: this.putUserData.first_name,
          last_name: this.putUserData.last_name,
          email: this.putUserData.email,
          phone_number: this.putUserData.phone_number,
          date_of_birth: this.putUserData.date_of_birth,
        };
      }
      if (this.modalUsagesFor === 2) {
        if (this.putUserData.role_id === '') {
              this.showToastMessage({message: 'Please select a role', type: 'error'});
              return ;
            }
        data = {
          role_id: this.putUserData.role_id,
        };
      }

      const dataObj = {
        id: this.userId,
        data: data,
      };

      await this.putUser(dataObj).then(async (response) => {
        if (response.status === 200) {
          document.querySelector(`[data-target="#${this.modalName}"]`).click(); // modal close

          let toastObj = {message: 'Info has Updated', type: 'success'};
          this.showToastMessage(toastObj);

          if (this.modalUsagesFor === 0) {
            this.$emit('updatedUserAndRoleData', {
              userData: {
                firstName: this.user.first_name,
                lastName: this.user.last_name,
                email: this.user.email,
                phoneNumber: this.user.phone_number,
                dateOfBirth: this.user.date_of_birth,
              },
              userRoleData: {
                id: this.user.role ? this.user.role.id : '',
                type: this.user.role ? this.user.role.type : '',
                name: this.user.role ? this.user.role.name : '',
                description: this.user.role ? this.user.role.name : '',
              },
            });
          }

          if (this.modalUsagesFor === 1) {
            this.$emit('updatedUserData', {
              userData: {
                firstName: this.user.first_name,
                lastName: this.user.last_name,
                email: this.user.email,
                phoneNumber: this.user.phone_number,
                dateOfBirth: this.user.date_of_birth,
              },
            });
          }

          if (this.modalUsagesFor === 2) {
            this.$emit('updatedUserRoleData', {
              userRoleData: {
                id: this.user.role ? this.user.role.id : '',
                type: this.user.role ? this.user.role.type : '',
                name: this.user.role ? this.user.role.name : '',
                description: this.user.role ? this.user.role.name : '',
              },
            });
          }
        }

        this.errors.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
        this.errors.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
        this.errors.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
        this.errors.email = response.errors && response.errors.email ? response.errors.email[0] : '';
        this.errors.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';
        this.errors.dateOfBirth = response.errors && response.errors.date_of_birth ? response.errors.date_of_birth[0] : '';

        this.showToastMessage(response);
      });
    },
  },

  async mounted() {
    if (this.modalUsagesFor === 2) {
      await this.getRoleList();
    }
  },
}
</script>

<style>

</style>
